import server from '../request';

// 自定义表头查询
export const getCustomQueryColumn = ({ customizeSystemConfigCode, customizeBusinessTypeCode, tenantId}) => {
  return server.post('/xiaozi-saas/wireless/customize/queryList', {
    customizeSystemConfigCode,
    customizeBusinessTypeCode,
    tenantId,
  });
};

// 根据流程节点查询布局
export const queryLayoutByNodeApi = data => server.post('/xiaozi-saas/wireless/customize/queryLayoutByNode', data);

// 查询数据列表
export const getCommonQueryPageList = data => {
  const orderBy = [
    {
      orderByProperty: data.orderByProperty || 'lastUpdateTime',
      orderByType: data.orderByType || 'DESC',
    },
  ];
  const param = { orderBy, ...data };
  return server.post('/xiaozi-saas/wireless/customize/queryPageList', param);
};

// 详情
export const getCommonQueryPageDetail = data => server.post('/xiaozi-saas/wireless/customize/detail', data);
