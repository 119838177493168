<template>
  <div>
    <query-form labelWidth="130px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="维修商名称："  :span='8'>
        <el-input placeholder="请输入维修商名称" v-model.trim="query.tenantName"></el-input>
      </el-form-item>
      <el-form-item label="维修商注册时间：" class="JZCategoryFormType" :span='8'>
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.tenantCreatTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="医院名称：" :span='8'>
        <el-input placeholder="请输入医院名称" v-model.trim="query.customerName"></el-input>
      </el-form-item>
      <el-form-item label="添加医院时间：" class="JZCategoryFormType" :span='8'>
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.hospitalCreatTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="渠道名称：" :span='8'>
        <el-input placeholder="请输入渠道名称" v-model.trim="query.channelName"></el-input>
      </el-form-item>
      <el-form-item label="支付状态：" :span='8'>
        <el-select v-model.trim="query.payStatus" @change="handleSearch" clearable placeholder='请选择支付状态'>
          <el-option v-for="i in payStatusLists" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付时间：" class="JZCategoryFormType" :span='8'>
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.payTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="开票状态：" :span='8'>
        <el-select v-model="query.invoiceStatus" @change="handleSearch" clearable placeholder='请选择开票状态'>
          <el-option v-for="i in invoiceStatusLists" :key="i.value" :label="i.label" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票日期：" class="JZCategoryFormType" :span='8'>
        <el-date-picker
          @change='handleSearch'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          v-model.trim="query.drawTime"
          :default-time="['00:00:00', '23:59:59']">
        ></el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="orderList"
      class="content_block"
      :showRightToolbar="false"
      :columnList="columnList"
      :showFooter='true'
      :leftToolbar="tableButtons"
      :footerData="footerData"
      :pagination.sync="pagination"
      @size-change="getTargetLists"
      @current-change="getTargetLists"
    />
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { getQueryReportPage, getQueryReportCount, queryReportPageExcel} from '@/api/report';
import { mapGetters } from 'vuex';

export default {
  name: 'REPAIRMAN',
  mixins: [TablePaginationMixin],
  computed: {
    ...mapGetters(['actionPermissionMap']),
    tableButtons () {
      return [
        {
          name: '导出',
          status: 'primary',
          size: 'small',
          code: 'exportExcel',
          icon: 'el-icon-printer',
          disabled: this.isLoading,
          loading: this.isLoading,
          permission: 'saas_business_report:export',
          click: this.exportExcel,
        },
      ];
    },
  },
  data () {
    return {
      query: {
        tenantName: '',
        customerName: '',
        channelName: '',
        invoiceStatus: '',
        payStatus: '',
        drawTime: null,
        payTime: null,
        hospitalCreatTime: null,
        tenantCreatTime: null,
      },
      payStatusLists: [{value: '', name: '全部'}, {value: '1', name: '未支付'}, {value: '2', name: '已支付'}],
      invoiceStatusLists: [{label: '全部', value: ''}, {label: '未申请', value: '0'}, {label: '已申请', value: '1'}, {label: '开票中', value: '2'}, {label: '开票完成', value: '3'}, {label: '已寄出', value: '4'}],
      orderList: [],
      footerData: [],
      columnList: [
        {
          tooltip: true,
          label: '维修商名称',
          minWidth: '180px',
          prop: 'tenantName',
          fixed: 'left',
        },
        {
          tooltip: true,
          label: '维修商注册时间',
          minWidth: '150px',
          prop: 'tenantCreatTime',
        },
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '200px',
          prop: 'customerName',
        },
        {
          tooltip: true,
          label: '添加医院时间',
          minWidth: '150px',
          prop: 'hospitalCreatTime',
        },
        {
          tooltip: true,
          label: '渠道',
          minWidth: '150px',
          prop: 'channel',
        },
        {
          tooltip: true,
          label: '支付状态',
          minWidth: '200px',
          prop: 'payStatus',
        },
        {
          tooltip: true,
          label: '支付时间',
          minWidth: '150px',
          prop: 'paymentTime',
        },
        {
          tooltip: true,
          label: '支付金额',
          minWidth: '150px',
          prop: 'paymentAmount',
          format: val => this.$options.filters['formatThousand'](val),
        },
        {
          tooltip: true,
          label: '开票状态',
          minWidth: '100px',
          prop: 'invoiceStatus',
        },
        {
          tooltip: true,
          label: '开票日期',
          minWidth: '150px',
          prop: 'drawTime',
        },
        {
          tooltip: true,
          label: '到期时间',
          minWidth: '150px',
          prop: 'indate',
        },
        {
          tooltip: true,
          label: '订单号',
          minWidth: '150px',
          prop: 'orderCode',
        },
      ],
      isLoading: false,
    };
  },
  created () {
    this.handleSearch();
  },
  methods: {
    exportExcel () {
      this.handleSearch();
      // 导出接口
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      queryReportPageExcel(this.getCurrentParams()).then(res => {
        this.isLoading = false;
        let headerName = `业务数据明细表_${Date.now()}`;
        let fileName = decodeURI(headerName);
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); // 注意你的文件类型，我的是excel，需要什么类型文件，对应修改即可
        // IE兼容
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          var a = document.createElement('a');
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.setAttribute('id', 'export');
          document.body.appendChild(a); // 修复firefox中无法触发click， platform 为我本页div 的id， 此处可直接用document.body
          a.click();
          document.getElementById('export').remove();
        }
      }).then(() => {
        this.isLoading = false;
      });
    },
    getCurrentParams () {
      let {drawTime, hospitalCreatTime, payTime, tenantCreatTime} = this.query;
      return {
        ...this.query,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
        drawStartTime: drawTime && drawTime.length > 0 ? drawTime[0] : null,
        drawEndTime: drawTime && drawTime.length > 0 ? drawTime[1] : null,
        hospitalCreatTimeStart: hospitalCreatTime && hospitalCreatTime.length > 0 ? hospitalCreatTime[0] : null,
        hospitalCreatTimeEnd: hospitalCreatTime && hospitalCreatTime.length > 0 ? hospitalCreatTime[1] : null,
        paymentStartTime: payTime && payTime.length > 0 ? payTime[0] : null,
        paymentEndTime: payTime && payTime.length > 0 ? payTime[1] : null,
        tenantCreatTimeStart: tenantCreatTime && tenantCreatTime.length > 0 ? tenantCreatTime[0] : null,
        tenantCreatTimeEnd: tenantCreatTime && tenantCreatTime.length > 0 ? tenantCreatTime[1] : null,
      };
    },
    async getTargetLists () {
      let param = this.getCurrentParams();
      const res = await getQueryReportPage(param);
      const total = await getQueryReportCount(param);
      let info = res.body;
      let totalPage = total.body;
      if (res.heads.code === 200) {
        this.orderList = info.tenantOrderReportRespDto.list;
        this.pagination.total = info.tenantOrderReportRespDto.total;
      }
      let currentLists = [];
      let totalInfo = info.tenantOrderReportCountRespDto;
      // if (pageInfo.list.length) {
      currentLists.push({
        checkTitle: '页合计',
        paymentAmount: this.$options.filters['formatThousand'](totalInfo.totalPaymentAmount),
        payStatus: `已支付${totalInfo.totalPaid}笔，未支付${totalInfo.totalUnpaid}笔`,
      });
      currentLists.push({
        checkTitle: '总计',
        paymentAmount: this.$options.filters['formatThousand'](totalPage.totalPaymentAmount),
        payStatus: `已支付${totalPage.totalPaid}笔，未支付${totalPage.totalUnpaid}笔`,
      });
      // }
      this.$nextTick(() =>{
        this.footerData = this.orderList.length ? currentLists : [];
      });
    },
    handleActionClick ({ id }) {
      this.$router.push({
        name: 'REPORT_REPAIRMAN_HOSPITAL_DETAIL',
        query: { id },
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    handleReset () {
      this.query = {
        tenantName: '',
        customerName: '',
        channelName: '',
        invoiceStatus: '',
        payStatus: '',
        drawTime: null,
        payTime: null,
        hospitalCreatTime: null,
        tenantCreatTime: null,
      };
      this.handleSearch();
    },
  },
};
</script>
