<script>
import mixin from './mixin';
export default {
  // 联系时间 系统组件
  name: 'CContactTime',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      contactableType: 'ANY_TIME',
      contactTime: null,
      contactableStart: null,
      contactableEnd: null,
      OrderWorkDateTypeEnumList: [
        {
          value: 'WORKING_DAY',
          name: '工作日 ',
        },
        {
          value: 'WEEKEND',
          name: '双休日',
        },
        {
          value: 'ANY_TIME',
          name: '随时',
        },
      ],
    };
  },
  watch: {
    value () {
      this.setDefaultValue();
    },
  },
  methods: {
    setDefaultValue () {
      if (this.value) {
        let val = this.value;
        this.contactableType = val?.contactableType;
        if (val?.contactTime && val?.contactTime[0]) {
          this.contactTime = val?.contactTime;
          return;
        }
        if (val?.contactableStart && val?.contactableEnd) {
          this.contactTime = [val?.contactableStart, val?.contactableEnd];
          this.contactableStart = val?.contactableStart;
          this.contactableEnd = val?.contactableEnd;
        } else {
          this.contactTime = null;
          this.contactableStart = null;
          this.contactableEnd = null;
        }
      }
    },
    handleContactableTypeChanged () {
      if (this.contactableType === 'ANY_TIME') {
        this.contactableStart = null;
        this.contactableEnd = null;
        this.contactTime = null;
      }
      this.emitValue();
    },
    handleContactTimeChanged () {
      const [contactableStart, contactableEnd] = this.contactTime;
      this.contactableStart = contactableStart;
      this.contactableEnd = contactableEnd;
      this.emitValue();
    },
    emitValue () {
      const {contactableType, contactableStart, contactableEnd, contactTime} = this;
      this.$emit('input', {contactableType, contactableStart, contactableEnd, contactTime});
    },
  },
  render () {
    return (
      <div class="c-contact-time">
        <div>
          <el-select v-model={this.contactableType}
            disabled={this.inputDisabled}
            size={this.inputSize}
            onChange={this.handleContactableTypeChanged}
            placeholder='请选择可联系时间'>
            {
              this.OrderWorkDateTypeEnumList
                .map(i => <el-option label={i.name} value={i.value} key={i.value}></el-option>)
            }
          </el-select>
        </div>
        {
          this.contactableType !== 'ANY_TIME'
            ? <div>
              <el-time-picker
                class="w-100"
                is-range
                disabled={this.inputDisabled}
                size={this.inputSize}
                v-model={this.contactTime}
                onChange={this.handleContactTimeChanged}
                picker-options={{
                  format: 'HH:mm:ss',
                }}
                value-format="HH:mm:ss"
                format="HH:mm:ss"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
              </el-time-picker>
            </div>
            : null
        }
      </div>
    );
  },
  created () {
    this.setDefaultValue();
  },
};
</script>
<style scoped lang="scss">
.c-contact-time {
  display: flex;
  &>div:nth-child(1){
    flex: 8;
  }
  &>div:nth-child(2){
    flex: 16;
  }
}
</style>
