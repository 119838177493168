import { getDisplayValue, getDefaultValueByTag } from '../utils/component.js';
import {
  getCustomQueryColumn,
  getCommonQueryPageList,
  getCommonQueryPageDetail,
  queryLayoutByNodeApi,
} from '@/api/customForm';
import server from '@/request';
import _ from 'lodash';
export default {
  name: 'child_form_render',
  components: {
    Parser: () => import('../parser/Parser'),
  },
  data () {
    return {
      dialogVisible: false,
      dataStatus: 'NEW', // NEW:新增， EDIT:编辑
      childFormModel: {},
      dataList: [],
      columnList: [],
      childForm: null,
      childFormBtns: [],
    };
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  computed: {
    _elFormItemSize () {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize () {
      return this.scheme.__config__.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled () {
      return this.scheme.disabled || (this.elForm || {}).disabled;
    },
  },
  props: {
    scheme: Object,
    formModel: {
      type: Object,
      default: ()=>{
        return {};
      },
    },
    tenantId: String,
  },
  methods: {
    getTableColumns () {
      if (!this.formModel || this.scheme.relatedFormType === null || this.scheme.relatedFormType === undefined) {
        return Promise.reject();
      }
      return getCustomQueryColumn({
        customizeSystemConfigCode: this.scheme?.relatedFormType,
        tenantId: this.tenantId,
      }).then(res=>{
        res.body && res.body.forEach(col=>{
          this.columnList.push({
            tooltip: true,
            title: col.columnName,
            minWidth: '160px',
            field: col.columnKey,
            fixed: col.columnKey === 'code' ? 'left' : '',
            slots: {
              default: ({row }) =>{
                if (col.columnKey === 'code') {
                  return [(
                    <span class="text-primary cursor-pointer" onClick={this.handleAction.bind(this, row, 'EDIT')}>
                      {row.code}
                    </span>
                  )];
                } else {
                  const columnKeyInfo = row.columnKeyInfo || {};
                  const columnSchema = {
                    ...columnKeyInfo[col.columnKey],
                    __config__: {
                      tag: col.columnType,
                      renderKey: col.columnKey,
                    },
                  };
                  return [getDisplayValue(columnSchema, row)];
                }
              },
            },
          });
        });
      });
    },
    getTableDataList () {
      // 当查询的字段有值时再进行表格数据查询
      if (this.formModel && this.formModel[this.scheme.relatedFormQueryBy]) {
        let params = {
          systemConfigCodeFlag: this.scheme?.relatedFormType,
          tenantId: this.tenantId,
        };
        params[this.scheme.relatedFormFillField] = this.formModel[this.scheme.relatedFormQueryBy];
        getCommonQueryPageList(params).then(res=>{
          this.dataList = res.body?.list || [];
        });
      }
    },
    handleFormBtnClick (btn) {
      const getQueryString = url=>{
        let ret = {};
        if (url) {
          const search = url.split('?');
          if (search[1]) {
            const searchParams = new URLSearchParams(search[1]);
            searchParams.forEach((value, key)=>{
              ret[key] = value;
            });
          }
        }
        return ret;
      };
      this.$refs[this.scheme.__config__.renderKey].validForm().then(()=>{
        let params = {
          ...this.childFormModel,
          ...getQueryString(btn.pcUrl),
          tenantId: this.tenantId,
        };
        params[this.scheme.relatedFormFillField] = this.formModel[this.scheme.relatedFormQueryBy];
        server[btn.httpMethod](btn.pcUrl, params).then(()=>{
          this.dialogVisible = false;
          this.getTableDataList();
        });
      });
    },
    handleAction (row, action) {
      if (action === 'EDIT') {
        getCommonQueryPageDetail({
          id: row.id,
          systemConfigCodeFlag: this.scheme?.relatedFormType,
          tenantId: this.tenantId,
        }).then(res=>{
          queryLayoutByNodeApi({
            customizeBusinessTypeId: res.body?.typeMsg?.id,
            status: res.body?.statusMsg.value,
            tenantId: this.tenantId,
          }).then(model=>{
            let formConf = model.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
            this.childForm = formConf;
            if (this.inputDisabled) {
              this.childForm.disabled = true;
            } else {
              this.childFormBtns = model.body?.customizeBusinessProcessResDTO?.customizeButtonResDTOList?.filter(t=>t.pcUrl) || [];
              // 操作按钮为空时禁用表单
              if (!this.childFormBtns[0]) {
                this.childForm.disabled = true;
              }
            }
            this.getViewModel(this.childForm.fields);
            Object.keys(res.body).forEach(key=>{
              if (res.body[key]) {
                this.$set(this.childFormModel, key, res.body[key]);
              }
            });
            this.dataStatus = 'EDIT';
            this.dialogVisible = true;
            this.$nextTick(()=>{
              this.$refs[this.scheme.__config__.renderKey].resetForm();
            });
          });
        });
      }
    },
    getViewModel (nodeList) {
      nodeList && nodeList.forEach(node=>{
        if (node?.__config__?.layout === 'groupRowFormItem') {
          this.getViewModel(node?.__config__?.children);
        } else {
          this.$set(this.childFormModel, node.__config__.renderKey, getDefaultValueByTag(node));
        }
      });
    },
    handleNew () {
      queryLayoutByNodeApi({
        code: this.scheme?.relatedFormTemplateCode,
        status: 'NEW',
        tenantId: this.tenantId,
      }).then(res=>{
        let formConf = res.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
        this.childForm = formConf;
        this.childFormBtns = res.body?.customizeBusinessProcessResDTO?.customizeButtonResDTOList.filter(t=>t.pcUrl) || [];
        this.getViewModel(this.childForm.fields);
        this.childFormModel.typeMsg = res.body.customizeBusinessTypeResDTO;
        this.dialogVisible = true;
        this.dataStatus = 'NEW';
        this.$nextTick(()=>{
          this.$refs[this.scheme.__config__.renderKey].resetForm();
        });
      });
    },
  },
  render () {
    let columns = [].concat(this.columnList);
    let toolbarConfig = this.inputDisabled ? null : {
      import: false,
      export: false,
      zoom: false,
      custom: false,
      slots: {
      buttons: ({ x }, h) => { // eslint-disable-line
          return [ h('el-button', {
            props: {
              type: 'primary',
              size: this.inputSize,
            },
            on: {
              click: this.handleNew,
            },
          }, '新增')];
        },
      },
    };
    let footer = [];
    if (this.scheme.sumBy) {
      let sumItem = new Array(columns.length);
      sumItem[0] = '合计';
      this.scheme.sumBy.forEach(key=>{
        let index = columns.findIndex(t=>t.field === key);
        if (index >= 0) {
          sumItem[index] = _.sum(this.dataList.map(t=>isNaN(t[key]) ? 0 : Number(t[key])));
          sumItem[index] = sumItem[index].toFixed(2);
        }
      });
      footer.push(sumItem);
    }
    let table = (<vxe-grid resizable={true}
      size={this.inputSize}
      data={this.dataList}
      ref="tableLayout"
      align="center"
      maxHeight="500px"
      show-footer={!!(this.scheme.sumBy && this.scheme.sumBy[0])}
      footer-method={()=>footer}
      toolbarConfig={toolbarConfig}
      autoResize={true}
      show-overflow={true}
      highlight-current-row={true}
      highlight-hover-row={true}
      highlight-current-column={true}
      highlight-hover-column={true}
      border="full"
      columns={columns}>
    </vxe-grid>);
    let dialog = <el-dialog title={this.dataStatus === 'NEW' ? '新增' : '编辑'}
      visible={true}
      close-on-click-modal={false}
      append-to-body={true}
      width="1200px"
      onClose={()=>{ this.dialogVisible = false; }}>
      <Parser ref={this.scheme.__config__.renderKey}
        form-conf={this.childForm}
        formModel={this.childFormModel}/>
      <span slot="footer">
        <el-button onClick={()=>{ this.dialogVisible = false; }}>取 消</el-button>
        {
          this.childFormBtns.map(btn=>{
            return <el-button key={btn.id} v-auth={btn.pcAuthCode} onClick={this.handleFormBtnClick.bind(this, btn)}>{btn.name}</el-button>;
          })
        }
      </span>
    </el-dialog>;
    return <div>
      {table}
      {this.dialogVisible ? dialog : null}
    </div>;
  },
  created () {
    this.getTableColumns().then(this.getTableDataList);
  },
};
