import server from '@/request';

//  维修商数据统计表 功能----Begin
export const getwirelessReport = ({ name, channelName, pageNum, pageSize }) =>
  server.post('/xiaozi-saas/wireless/tenant/queryReport', { name, channelName, pageNum, pageSize });

export const getwirelessReportDetail = ({ id }) => server.post('/xiaozi-saas/wireless/tenant/queryById', { id });

export const getwirelessReportDetailTable = ({ id, payStatus, pageNum, pageSize }) =>
  server.post('/xiaozi-saas/wireless/tenant/queryReportDetail', { id, payStatus, pageNum, pageSize });
//  维修商数据统计表 功能----End

// 业务数据明细表----Begin
// 功能描述：查询-业务数据明细表数量
export const getQueryReportCount = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryReportCount', params);
// 功能描述：查询-业务数据明细表
export const getQueryReportPage = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryReportPage', params);
// 功能描述：导出-业务数据明细表
export const queryReportPageExcel = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryReportPageExcel', params, {
    responseType: 'blob',
  });
// 业务数据明细表----End

// 工单监控----Begin
// 功能描述：查询工单状态数量
export const getQueryWorkOrderCount = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryWorkOrderCount', params);
// 功能描述：查询工单详情
export const getQueryWorkOrderDetail = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryWorkOrderDetail', params);

// 功能描述：查询工单模板
export const getQueryWorkOrderTemplate = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryLayoutByNode', params);
// 功能描述：查询工单列表
export const getQueryWorkOrderPage = (params) =>
  server.post('/xiaozi-saas/wireless/tenant/queryWorkOrderPage', params);
// 工单监控----End
