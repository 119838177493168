<template>
  <div>
    <ContentBlock style="padding: 0 10px 84px 10px;">
      <div class="topTitleContainer">
        <span>协同状态
          <el-radio-group v-model="outform.cooperationStatus" @change="handleSearch" style="margin-left: 14px;">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button :label="1">已协同</el-radio-button>
            <el-radio-button :label="2">已关闭</el-radio-button>
            <el-radio-button :label="3">已失效</el-radio-button>
          </el-radio-group>
        </span>
      </div>
      <TableWithPagination class="special" :columnList="columnList" :tableData="targetLists" :toolBar="toolBar"
        :sortInfo.sync="sortInfo" tableBorder="default" :showRightToolbar="true" :pagination.sync="pagination"
        @size-change="getTargetLists" @current-change="getTargetLists" @sort-change="getTargetLists"
        :highHoverRow="false" :highlightRow="false" />
    </ContentBlock>
    <el-drawer title="高级筛选" width="400" direction="rtl" :modal-append-to-body="false" :visible.sync="isShow"
      :close-on-click-modal="false" @closed="isShow = false">
      <el-form label-width="auto" label-position="right" style="margin-top: 20px;padding: 10px;">
        <el-row>
          <el-col :span='24'>
            <el-form-item label="协同状态">
              <el-select v-model.trim="form.cooperationStatus" placeholder="请选择协同订阅状态" clearable
                :popper-append-to-body="false">
                <el-option label="全部" value=""></el-option>
                <el-option label="已协同" :value="1"></el-option>
                <el-option label="已关闭" :value="2"></el-option>
                <el-option label="已失效" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="协同类型">
              <el-select v-model.trim="form.cooperationType" placeholder="请选择协同类型" clearable
                :popper-append-to-body="false">
                <el-option label="全部" value=""></el-option>
                <el-option label="采购协同" :value="1"></el-option>
                <el-option label="销售协同" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="发起方名称">
              <el-input placeholder="请输入发起方名称" v-model.trim="form.ledgerEnterpriseName" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="发起方社会统一信用代码">
              <el-input placeholder="请输入发起方发起方社会统一信用代码" v-model.trim="form.ledgerUnifiedSocialCreditCode"
                clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="发起方平台企业号">
              <el-input placeholder="请输入发起方平台企业号" v-model.trim="form.ledgerEnterpriseCode" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="接收方名称">
              <el-input placeholder="请输入接收方名称" v-model.trim="form.enterpriseName" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="接收方社会统一信用代码">
              <el-input placeholder="请输入接收方社会统一信用代码" v-model.trim="form.unifiedSocialCreditCode" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="接收方平台企业号">
              <el-input placeholder="请输入接收方平台企业号" v-model.trim="form.tenantCode" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="创建人">
              <el-input placeholder="请输入创建人" v-model.trim="form.createUserName" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="联系方式">
              <el-input placeholder="请输入联系方式" v-model.trim="form.createUserPhone" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="更新开始时间">
              <el-date-picker v-model.trim="form.lastUpdateTimeStart" placeholder="选择发起时间" type="datetime"
                value-format="timestamp" :append-to-body="false">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="更新结束时间">
              <el-date-picker v-model.trim="form.lastUpdateTimeEnd" placeholder="选择发起时间" type="datetime"
                value-format="timestamp" :append-to-body="false">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center;">
        <el-button @click="handleEmpty">清空</el-button>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { cooperationEnterprisePageList, cooperationEnterpriseClose, cooperationEnterpriseOpen } from '@/api/cooperation';
import * as dayjs from 'dayjs';
import icRefresh from '@/assets/table/ic_refresh.svg';
import icAdvanceSearch from '@/assets/table/ic_advanceSearch.svg';

export default {
  name: 'CooperationSubscription',
  components: {
    TableWithPagination,
  },
  mixins: [TablePaginationMixin],
  computed: {
    toolBar () {
      let currentLists = [
        {
          type: 'custom',
          render: () => {
            return (
              <el-input placeholder='请输入发起方企业名称' style='width:250px;' v-model={this.outform.ledgerEnterpriseName}>
                <el-button slot="suffix" icon="el-icon-search" onClick={this.handleSearch} style='position: absolute;height: 100%;right: -5px;top: 0;'>
                </el-button>
              </el-input>
            );
          },
        },
        {
          type: 'custom',
          render: () => {
            return (
              <vxe-button circle onClick={this.handleAdvancedSearch}>
                <img style='font-size:14px;width:15px;height:15px;' src={icAdvanceSearch} />
              </vxe-button>
            );
          },
        },
        {
          type: 'custom',
          render: () => {
            return (
              <vxe-button circle onClick={this.handleReset}>
                <img style='font-size:14px;width:15px;height:15px;' src={icRefresh} />
              </vxe-button>
            );
          },
        },
      ];
      return currentLists;
    },
    columnList () {
      const cols = [
        {
          tooltip: true,
          label: '协同状态',
          minWidth: '100px',
          width: '',
          prop: 'cooperationStatusDesc',
        },
        {
          tooltip: true,
          label: '协同类型',
          minWidth: '100px',
          width: '',
          prop: 'cooperationTypeDesc',
        },
        {
          tooltip: true,
          label: '发起方名称',
          minWidth: '150px',
          width: '',
          prop: 'ledgerEnterpriseName',
        },
        {
          tooltip: true,
          label: '发起方社会统一信用代码',
          minWidth: '170px',
          width: '',
          prop: 'ledgerUnifiedSocialCreditCode',
        },
        {
          tooltip: true,
          label: '发起方平台企业号',
          minWidth: '160px',
          width: '',
          prop: 'ledgerEnterpriseCode',
        },
        {
          tooltip: true,
          label: '接收方名称',
          minWidth: '150px',
          width: '',
          prop: 'enterpriseName',
        },
        {
          tooltip: true,
          label: '接收方社会统一信用代码',
          minWidth: '170px',
          width: '',
          prop: 'unifiedSocialCreditCode',
        },
        {
          tooltip: true,
          label: '接收方平台企业号',
          minWidth: '160px',
          width: '',
          prop: 'tenantCode',
        },
        {
          tooltip: true,
          label: '创建人',
          minWidth: '100px',
          width: '',
          prop: 'createUserName',
        },
        {
          tooltip: true,
          label: '联系方式',
          minWidth: '100px',
          width: '',
          prop: 'createUserPhone',
        },
        {
          tooltip: true,
          label: '更新时间',
          minWidth: '100px',
          width: '',
          prop: 'createTime',
          format: (v) => {
            return this.formatTime(v);
          },
        },
      ];

      cols.push({
        label: '操作',
        prop: 'action',
        minWidth: '100px',
        fixed: 'right',
        render: (h, { row }) => {
          let actions = [];

          if (row.cooperationStatus === 1) {
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              on: {
                click: () => { this.handleClose(row); },
              },
            }, '关闭'));
          }

          if (row.cooperationStatus === 2) {
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              on: {
                click: () => { this.handleOpen(row); },
              },
            }, '开启'));
          }

          return h('div', actions);
        },
      });

      return cols;
    },
  },
  data () {
    return {
      outform: {
        cooperationStatus: '',
        cooperationType: '',
        ledgerEnterpriseName: '',
        ledgerUnifiedSocialCreditCode: '',
        ledgerEnterpriseCode: '',
        enterpriseName: '',
        unifiedSocialCreditCode: '',
        tenantCode: '',
        createUserName: '',
        createUserPhone: '',
        lastUpdateTimeStart: '',
        lastUpdateTimeEnd: '',
      },
      form: {
        cooperationStatus: '',
        cooperationType: '',
        ledgerEnterpriseName: '',
        ledgerUnifiedSocialCreditCode: '',
        ledgerEnterpriseCode: '',
        enterpriseName: '',
        unifiedSocialCreditCode: '',
        tenantCode: '',
        createUserName: '',
        createUserPhone: '',
        lastUpdateTimeStart: '',
        lastUpdateTimeEnd: '',
      },
      targetLists: [],
      isShow: false,
    };
  },
  methods: {
    formatTime (v) {
      let ret = '';

      if (v) {
        ret = dayjs(v * 1).format('YYYY-MM-DD HH:mm');
      }

      return ret;
    },
    handleClose (row) {
      this.$confirm('当前已与该企业/组织建立外部协同，点击【确认】后，将无法再进行外部协同', '关闭协同', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'W400',
      }).then(() => {
        cooperationEnterpriseClose({ id: row.id }).then(() => {
          this.$message.success('取消成功');

          this.getTargetLists();
        });
      });
    },
    handleOpen (row) {
      this.$confirm('确认开启', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'W400',
      }).then(() => {
        cooperationEnterpriseOpen({ id: row.id }).then(() => {
          this.$message.success('开启成功');

          this.getTargetLists();
        });
      });
    },
    handleQuery () {
      this.pagination.currentPage = 1;

      this.getTargetLists(this.form);

      this.$nextTick(() => {
        this.outform = { ...this.form };
        this.isShow = false;
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;

      this.getTargetLists();
    },
    handleReset () {
      this.outform = {
        ...this.$options.data().form,
      };

      this.pagination.currentPage = 1;

      this.getTargetLists();
    },
    handleEmpty () {
      this.form = this.$options.data().form;
    },
    getTargetLists (form = this.outform) {
      const params = {
        ...form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };

      if (this.sortInfo.orderByProperty && this.sortInfo.orderByType) {
        params.orderBy = [this.sortInfo];
      }

      cooperationEnterprisePageList(params).then(({ body }) => {
        this.targetLists = body?.list || [];

        this.pagination.total = body?.total || 0;
      });
    },
    handleAdvancedSearch () {
      this.form = { ...this.outform };

      this.isShow = true;
    },
  },
  created () {
    this.handleSearch();
  },
};
</script>
<style scoped lang='scss'>
/deep/ .topTitleContainer {
  height: 60px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px 0px #DFE2E8;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #606266;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

.title-box {
  color: #303133;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-box {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    i {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/.vxe-tools--wrapper {
  display: flex;
  justify-content: space-between;

  &>.el-button {
    margin-left: 0.5em;
  }

  &>div.el-input {
    margin-left: 0.5em;
  }

  &>.vxe-button {
    margin-left: 0.5em;
  }
}

/deep/ .vxe-tools--operate {
  &>.vxe-button {
    margin-left: 0.5em;
  }
}

/deep/ .action {
  color: #409EFF;
  cursor: pointer;

  &:hover {
    color: rgba(64, 158, 255, 0.7);
    text-decoration: underline;
  }
}

/deep/ .el-date-editor.el-input {
  width: -webkit-fill-available;
}
</style>
