<template>
  <div class="c-work-hour">
    <vxe-toolbar ref="xToolbar" v-if="!disabled">
      <template #buttons>
        <el-button type="primary" size="small"  @click="handleInsert">新增</el-button >
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      size="small"
      border
      align="center"
      auto-resize
      resizable
      show-overflow
      :data="tableData"
      :edit-config="{trigger: 'click', mode: 'cell'}">
      <vxe-column type="seq" width="60"></vxe-column>
      <vxe-column field="principalName" title="维修工程师" min-width="100px"  :edit-render="disabled?null:{}">
      </vxe-column>
      <vxe-column field="maintenanceDate" title="处理日期" :formatter="dateFormatter"  min-width="100px"  :edit-render="disabled?null:{}">
        <template #edit="{ row }">
          <el-date-picker popper-class='vxe-table--ignore-clear' size="small" v-model="row.maintenanceDate" value-format="yyyy/MM/DD"  type="date" placeholder="选择日期"></el-date-picker>
        </template>
      </vxe-column>
      <vxe-column field="startTime" title="开始时间" min-width="100px" :formatter="timeFormatter" :edit-render="disabled?null:{}">
        <template #edit="{ row }">
          <el-time-picker popper-class='vxe-table--ignore-clear' size="small" v-model="row.startTime" value-format="HH:mm:ss"  @change="handleTimeChanged(row,'startTime',$event)"   placeholder="选择时间"> </el-time-picker>
        </template>
      </vxe-column>
      <vxe-column field="endTime" title="结束时间" min-width="100px" :formatter="timeFormatter" :edit-render="disabled?null:{}">
        <template #edit="{ row }">
          <el-time-picker popper-class='vxe-table--ignore-clear' size="small"  v-model="row.endTime" value-format="HH:mm:ss"  @change="handleTimeChanged(row,'endTime',$event)"  placeholder="选择时间"> </el-time-picker>
        </template>
      </vxe-column>
      <vxe-column field="maintenanceWork" title="维修时间" min-width="100px" >
      </vxe-column>
      <vxe-column field="travelTime" title="旅行时间(小时)" min-width="150px"  :edit-render="disabled?null:{}">
        <template #edit="{ row }">
          <el-input-number v-model="row.travelTime" controls-position="right" :min="1" size="small"></el-input-number>
        </template>
      </vxe-column>
      <vxe-column field="endState" title="设备状态" min-width="100px"  :edit-render="disabled?null:{}">
        <template #default="{ row }">
          <span>{{ OrderDeviceStatusEnum[row.endState] }}</span>
        </template>
        <template #edit="{ row }">
          <el-select popper-class='vxe-table--ignore-clear' v-model="row.endState" size="small" placeholder="请选择">
            <el-option
              v-for="item in OrderDeviceStatusEnumList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import OrderDeviceStatusEnum, { OrderDeviceStatusEnumList } from '@/enums/OrderDeviceStatusEnum';
export default {
  // 工时统计 系统组件
  name: 'c-work-hour',
  components: {
  },
  props: {
    // disabled: Boolean, // pc在不支持新增新增工时，默认全部禁用
    value: {
      type: Array,
    },
  },
  data () {
    return {
      disabled: true,
      OrderDeviceStatusEnumList,
      OrderDeviceStatusEnum,
      tableData: this.value,
    };
  },
  methods: {
    dateFormatter ({cellValue}) {
      if (!cellValue) {
        return '';
      }
      if (dayjs(Number(cellValue)).isValid()) {
        return dayjs(Number(cellValue)).format('YYYY-MM-DD');
      } else {
        return cellValue;
      }
    },
    timeFormatter ({cellValue}) {
      if (!cellValue) {
        return '';
      }
      if (!isNaN(cellValue)) {
        return dayjs(Number(cellValue)).format('HH:mm');
      } else if (dayjs(cellValue).isValid()) {
        return dayjs(cellValue).format('HH:mm');
      } else {
        return cellValue;
      }
    },
    handleInsert () {
      // this.tableData.push({
      //   principalName: null,
      //   principalId: null,
      //   maintenanceDate: null,
      //   startTime: null,
      //   endTime: undefined,
      //   maintenanceWork: null,
      //   travelTime: null,
      //   endState: null,
      // });
      this.tableData.push({});
    },
    handleTimeChanged (row, currentField, val) {
      row.maintenanceWork = null;

      if (currentField === 'startTime') {
        if (!row.endTime) {
          row.startTime = val;
          return;
        }
        if (row.startTime < row.endTime) {
          row.maintenanceWork = Math.round((new Date(`1970/01/01 ${row.endTime}`).getTime() - new Date(`1970/01/01 ${row.startTime}`).getTime()) / 1000 / 60 / 60, 2);
        } else {
          row.endTime = null;
        }
      } else if (currentField === 'endTime') {
        if (!row.startTime) {
          row.endTime = val;
          return;
        }
        if (row.startTime < row.endTime) {
          row.maintenanceWork = Math.round((new Date(`1970/01/01 ${row.endTime}`).getTime() - new Date(`1970/01/01 ${row.startTime}`).getTime()) / 1000 / 60 / 60, 2);
        } else {
          row.startTime = null;
        }
      }
      // let index = compareFileds.findIndex(t=>t === currentField);
      // compareFileds.splice(index, 1);
      // if (row[currentField] > row[compareFileds[0]]) { console.log(row.startTime); }
      // console.log(row.endTime);
    },
  },
  mounted () {
    !this.disabled && this.$nextTick(() => {
      // 将表格和工具栏进行关联
      this.$refs.xTable.connect(this.$refs.xToolbar);
    });
  },
};
</script>
<style scoped lang="scss">
  .c-work-hour {
    .el-date-editor {
      width: 100%;
    }
  }
</style>
